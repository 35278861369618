$modal-content-bg:                  null !default;
$modal-content-border-color:        transparent !default;

html {
  font-size: 62.5%;

  &.sr .load-hidden {
    visibility: hidden;
  }
}

.container {
  padding-left: 7em;
  padding-right: 7em;

  @include respond(phone) {
    padding-left: 2em;
    padding-right: 2em;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.rounded img {
  border-radius: .75rem;
}

.rounded {
  border-radius: .75rem !important;
}

.modal-content {
  background-color: transparent !important;
  border: 0 !important;
  z-index: 9999999;
}
