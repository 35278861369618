@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

h1 {
  font-weight: 700;
}

.font-weight-bold {
  font-weight: bolder;
}

.font-italic {
  font-style: italic;
}

p,
a {
  font-family: "Montserrat", sans-serif;
  font-size: $default-font-size;
}

a,
a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}

a:hover {
  transition: all 0.3s ease-in-out;
}

.section-title {
  margin: 4.5rem 0;
  font-size: $big-font-size;
  font-weight: bold;
  text-transform: uppercase;

  @include respond(phone) {
    font-size: 2.8rem;
  }
}

.dark-blue-text {
  color: $dark-blue-text !important;
}

.text-color-main {
  @include supportColorForIE11();

  // Responsive text purple style
  @include respond(phone) {
    background-image: none;
    -webkit-text-fill-color: $secondary-color;
  }

  &:hover {
    transform: translateX(2px);
  }
}

.showcase {
  color: inherit;
  white-space: nowrap;
  position: relative;
  z-index: 10;
  text-decoration: none;
}

.showcase::before {
  content: "";
  width: 100%;
  height: 20%;
  background: #ffdc00;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 2px;
  transform: rotate(-1deg);
  transition: all 0.2s;
}
.showcase:hover::before {
  height: 35%;
  bottom: 0;
}
